<template>
  <div class="background-container image">
    <div class="container">
      <div class="card info-loading-card">
        <div class="video-container">
          <video class="video-content" autoplay muted loop :src="info_video"></video>
        </div>
        <div class="loading-bar">
          <div class="progress-container">
            <div class="progress" :style="{ width: `${progress}%` }"></div>
          </div>
        </div>
        <p class="loading-text">Preparando tudo para seu início...</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoPlan',
  data() {
    return {
      info_video: 'https://s3.us-east-1.wasabisys.com/adminer/cadastro/info-product-lazy_loading_compressed.mp4',
      progress: 0,
    };
  },
  computed: {
    getUser() {
      return this.$store.getters.currentUser || {};
    },
  },
  async mounted() {
    if (!this.$store.getters.hasSession) {
      return this.$router.push({ name: 'UserRegister' })
    }

    if (!this.getUser.id) {
      await this.$store.dispatch('fetchUser');
    }
    this.simulateLoading();
  },
  methods: {
    simulateLoading() {
      const totalSteps = 90;
      const stepDuration = 100;

      const simulateStep = () => {
        this.progress += 100 / totalSteps;
        if (this.progress > 100) {
          this.goToStripeCheckout();
        }
        else {
          setTimeout(simulateStep, stepDuration);
        }
      };

      simulateStep();
    },
    async goToStripeCheckout() {

      const user = this.getUser

      let plan = user.diamond_config.monthly;
      let amount = parseInt(plan.amount);
      let isTrial = plan.trial_days > 0;

      let successUrl = `${process.env.VUE_APP_API_URL}/users/me/subscriptions/new?session_id={CHECKOUT_SESSION_ID}&value=${amount}&trial=${isTrial}`
      let cancelUrl = `${process.env.VUE_APP_APP_URL}/plano-recomendado`

      const data = {
        mode: 'subscription',
        price_id: plan.stripe_id,
        return_full_session: true,
        cancel_url: cancelUrl,
        success_url: `${process.env.VUE_APP_API_URL}/users/session_authorize/new?email=${user.email}&token=${user.token}&redirect=${successUrl}`,
      };

      const response = await this.$store.dispatch('createCheckout', data);
      window.location.href = response.session_id.url;
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 16px;
}

.video-content {
  width: 100%;
}

.video-container {
  margin-bottom: 32px;
}

.loading-bar {
  width: 100%;
  padding: 4px;
  height: 24px;
  background: var(--light-light-2, #E9E9E9);
  border-radius: 6px;
}

.progress-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.progress {
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: 16px;
  background: var(--Contrast-Gradiente-0, linear-gradient(180deg, #F29932 0%, #E75341 100%));
}

.loading-text {
  display: flex;
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
  color: #FFFFFF;
  justify-content: center;
}

@media screen and (max-width: 479px) {

  .container {
    padding-top: 25%;
  }

  .background-container {
    display: flex;
    height: 100%;
    background-color: #2D2D2D;
  }

  .info-container {
    height: 100%;
  }

  .video-content {
    width: 100%;
  }

  .info-loading-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 32px;
  }

  .loading-text {
    display: flex;
    margin-top: 8px;
    font-size: 16px;
    color: #FFFFFF;
    justify-content: center;
  }

  .video-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }

  .loading-bar {
    height: 16px;
  }

  .progress {
    height: 8px;
  }
}
</style>

<template>
  <header class="section_plan wf-section inherited-styles-for-exported-element">
    <monthly-desktop :occurrence="'quarterly'" @success="successDesktop" />
    <monthly-mobile :occurrence="'quarterly'" @success="successMobile" />
    <div class="padding-global">
      <div class="container-medium">
        <div class="plan_component">
          <div class="wrap-content">
            <div class="heading_wrap text-color-light text-align-center">
              <h1 class="display-inline heading-style-h2">{{ $t('components.plans.diamond.title') }} <span class="text-color-gradiente-0" style="-webkit-background-clip: text !important;">Diamond</span></h1><img src="https://uploads-ssl.webflow.com/63a1b54c23a6b7879791f4a4/63a4e92714e99ca21be10f88_stop-foguete-onfire.png" loading="lazy" sizes="(max-width: 479px) 33.59375px, (max-width: 767px) 36.796875px, (max-width: 1439px) 46.390625px, (max-width: 1919px) 3vw, 51.1875px" srcset="https://uploads-ssl.webflow.com/63a1b54c23a6b7879791f4a4/63a4e92714e99ca21be10f88_stop-foguete-onfire-p-500.png 500w, https://uploads-ssl.webflow.com/63a1b54c23a6b7879791f4a4/63a4e92714e99ca21be10f88_stop-foguete-onfire.png 602w" alt="" class="plan_image-logo">
            </div>
            <div class="plan_title-custom">
              <div class="plan_title-description-wrap">
                <div class="align-center text-align-center text-style-custom" v-html="$t('components.plans.diamond.description')"></div>
              </div>
            </div>
          </div>
          <div class="pricing-table-wrapper">
            <div class="pricing-table-header">
              <div v-if="computedHasAffiliate && computedHasDiscount && computedDiscountText" class="off_wrap bg-white" style="height: 2.5rem; display: flex;">
                <div class="text-size-small text-align-center monthly-text">{{ $t('components.plans.diamond.card.header.discount.affiliate', { discount: computedDiscountText, affiliateName: user.affiliate_name }) }}</div>
              </div>
              <div v-else-if="computedSelectedPlan == 'quarterly'" class="off_wrap text-color-light">
                <div class="text-size-small text-align-center text-weight-medium">{{ $t('components.plans.diamond.card.header.discount.quarterly') }}</div>
              </div>
              <div v-else-if="computedSelectedPlan == 'semester'" class="off_wrap text-color-light">
                <div class="text-size-small text-align-center text-weight-medium">{{ $t('components.plans.diamond.card.header.discount.semester') }}</div>
              </div>
              <div v-else-if="computedSelectedPlan == 'yearly'" class="off_wrap text-color-light">
                <div class="text-size-small text-align-center text-weight-medium">{{ $t('components.plans.diamond.card.header.discount.yearly') }}</div>
              </div>
              <div class="pricing_wrap-header-content">
                
                <div class="toggle-wrapper table mb-0">
                  <div v-if="computedShowToggle" class="toggle-bg table">
                    <div v-if="computedShowQuarterlyFirst" class="toggle-bg table">
                      <div @click="selectedPlan = 'quarterly'" class="toggle-text monthly">
                        <div v-if="computedSelectedPlan == 'quarterly'" class="toggle-btn table" style="width: 114px !important;"></div>
                        {{ $t('components.plans.diamond.card.header.occurrence.quarterly') }}
                      </div>
                      <div @click.once="showModal()" @click="selectedPlan = 'monthly'" class="toggle-text monthly">
                        <div v-if="computedSelectedPlan == 'monthly'" class="toggle-btn table"></div>
                        {{ $t('components.plans.diamond.card.header.occurrence.monthly') }}
                      </div>
                    </div>
                    <div v-else class="toggle-bg table">
                      <div @click="selectedPlan = 'monthly'" class="toggle-text monthly">
                        <div v-if="computedSelectedPlan == 'monthly'" class="toggle-btn table"></div>
                        {{ $t('components.plans.diamond.card.header.occurrence.monthly') }}
                      </div>
                      <div @click="selectedPlan = 'quarterly'" class="toggle-text monthly">
                        <div v-if="computedSelectedPlan == 'quarterly'" class="toggle-btn table" style="width: 114px !important;"></div>
                        {{ $t('components.plans.diamond.card.header.occurrence.quarterly') }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="monthly-affiliate-container">
                    <div class="monthly-affiliate-background">
                      <div class="monthly-affiliate-text">
                        {{ $t('components.plans.diamond.card.header.occurrence.monthly') }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="pricing-table-header-cols w-row">
                  <div class="pricing-table-cols w-col w-col-4 w-col-small-4 w-col-tiny-4">
                    <div class="pricing-card-header table mb-0">
                      <div class="pricing-table-price-block custom-pricing responsive-style-align-center">
                        <div class="pricing-table-price-block">
                          <div v-if="computedSelectedPlan == 'monthly'" class="pricing-currency v2 monthly-price-sign">{{ computedFormattedCurrency }}</div>
                          <div v-if="computedSelectedPlan == 'quarterly'" class="pricing-currency v2">{{ computedFormattedCurrency }}</div>
                          <div class="pricing_wrap-data text-align-left">
                            <div class="price_yearly text-color-light-3">
                              <div v-if="computedSelectedPlan == 'quarterly'" class="price_wrap-money-bad">
                                <div class="pricing-price v2 yearly-price style-custom">{{ user.diamond_config.monthly.amount }}</div>
                                <div class="line"></div>
                              </div>
                              <div v-else-if="computedSelectedPlan == 'monthly' && computedPlan.coupon && (computedPlan.coupon.amount_off || computedPlan.coupon.percent_off)" class="price_wrap-money-bad">
                                <div class="pricing-price v2 yearly-price style-custom">{{ parseInt(computedPlan.amount) }}</div>
                                <div class="line"></div>
                              </div>
                              <div v-else-if="computedSelectedPlan == 'yearly'" class="price_wrap-money-bad">
                                <div class="pricing-price v2 yearly-price style-custom">{{ parseInt(user.diamond_config.monthly.amount) }}</div>
                                <div class="line"></div>
                              </div>
                              <div v-else-if="computedSelectedPlan == 'semester'" class="price_wrap-money-bad">
                                <div class="pricing-price v2 yearly-price style-custom">{{ parseInt(user.diamond_config.monthly.amount) }}</div>
                                <div class="line"></div>
                              </div>
                              <div class="price_wrap-money-nice">
                                <div v-if="computedSelectedPlan == 'quarterly' || computedSelectedPlan == 'semester' || computedSelectedPlan == 'yearly' || (computedSelectedPlan == 'monthly' && computedPlan.coupon && (computedPlan.coupon.amount_off || computedPlan.coupon.percent_off))" class="text-size-tiny money-discount-text">{{ $t('components.plans.diamond.card.header.price.for') }}</div>
                                <div v-if="computedSelectedPlan == 'monthly' && computedPlan.coupon && computedPlan.coupon.amount_off" class="pricing-price v2 yearly-price">{{ parseInt(computedPlan.amount - computedPlan.coupon.amount_off) }}</div>
                                <div v-else-if="computedSelectedPlan == 'monthly' && computedPlan.coupon && computedPlan.coupon.percent_off" class="pricing-price v2 yearly-price">{{ parseInt(computedPlan.amount - (computedPlan.amount * (computedPlan.coupon.percent_off / 100))) }}</div>
                                <div v-else class="pricing-price v2 yearly-price" :class="{ 'style-custom': computedSelectedPlan == 'monthly' }">{{ parseInt(computedPlan.amount) }}</div>
                                <div class="price-description-text">
                                  <div v-if="!computedHasDiscount" class="description_wrap-money">
                                    <div class="text-size-tiny-custom">{{ $t('components.plans.diamond.card.header.price.perMonth') }}</div>
                                  </div>
                                  <div v-if="!computedHasDiscount && computedSelectedPlan == 'quarterly'" class="description_wrap-money">
                                    <div class="text-size-tiny-custom">{{ $t('components.plans.diamond.card.header.price.quarterlyCharged') }}</div>
                                  </div>
                                  <div v-if="!computedHasDiscount && computedSelectedPlan == 'semester'" class="description_wrap-money">
                                    <div class="text-size-tiny-custom">{{ $t('components.plans.diamond.card.header.price.semesterCharged') }}</div>
                                  </div>
                                  <div v-if="!computedHasDiscount && computedSelectedPlan == 'yearly'" class="description_wrap-money">
                                    <div class="text-size-tiny-custom">{{ $t('components.plans.diamond.card.header.price.yearlyCharged') }}</div>
                                  </div>
                                  <div v-if="!computedHasDiscount && computedSelectedPlan == 'monthly'" class="description_wrap-money">
                                    <div class="text-size-tiny-custom">{{ $t('components.plans.diamond.card.header.price.monthlyCharged') }}</div>
                                  </div>
                                  <div v-if="computedHasDiscount" class="description_wrap-money">
                                    <div class="text-size-tiny-custom">{{ $t('components.plans.diamond.card.header.price.firstCharge') }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="buttons_wrap d-block text-center">
                  <stripe-checkout ref="checkoutRef" :pk="user.stripe_public_key" :session-id="sessionId" />
                  <div class="bg-white text-center" v-if="computedHasTrial" style="padding: 2px 8px;border-radius:2px;transform: rotate(-1deg);width: 110% !important;margin-left: -5%;margin-bottom: 10px;">
                    <p class="m-0" style="font-size:14px;background: linear-gradient(180deg, #F29932 0%, #E75341 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-clip: text;text-fill-color: transparent;">
                      {{ $t('components.plans.diamond.card.header.cta.trial', { trialDays: this.computedTrial }) }}
                    </p>
                  </div>
                  <button v-if="computedPlan.gateway == 'stripe'" @click="goToStripeCheckout(computedPlan.stripe_id)" :disabled="computedIsLoading" class="button-primary is-light is-small w-button" style="width: 8rem ;">
                    <template v-if="computedIsLoading">
                      <i class="fe fe-loader"></i>
                    </template>
                    <template v-else>
                      {{ $t('components.plans.diamond.card.header.cta.subscribe') }}
                    </template>
                  </button>
                  <button v-if="computedPlan.gateway == 'guru'" @click="goToGuruCheckout(computedPlan.checkout_url)" :disabled="computedIsLoading" class="button-primary is-light is-small w-button" style="width: 8rem;">
                    <template v-if="computedIsLoading">
                      <i class="fe fe-loader"></i>
                    </template>
                    <template v-else-if="computedHasTrial">
                      {{ $t('components.plans.diamond.card.header.cta.testNow') }}
                    </template>
                    <template v-else>
                      {{ $t('components.plans.diamond.card.header.cta.subscribe') }}
                    </template>
                  </button>
                </div>
              </div>
            </div>
            <div class="pricing_wrap-description">
              <div class="pricing-table-category">
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.library.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.collection.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pricing-table-category">
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.testingPipeline.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pricing-table-row responsive-style-align-center">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.suppliers.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.advertiserCreatives.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.shopifyImport.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.community.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.copyAI.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.similarCreatives.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.trafficSpy.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pricing-table-category">
                <div class="pricing-table-row">
                  <div class="pricing-table-row-title">
                    <div class="pricing-card-row mobile-center">
                      <div class="pricing-row-text-custom title">{{ $t('components.plans.diamond.card.body.benefits.tiktokYoutube.title') }}</div>
                    </div>
                  </div>
                  <div class="pricing-table-row-cols w-row">
                    <div class="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                      <div class="pricing-table-content">
                        <div class="pricing-card-row center">
                          <div class="pricing-icon table"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';

import MonthlyMobile from '@/components/plans/modals/MonthlyMobile.vue';
import MonthlyDesktop from '@/components/plans/modals/MonthlyDesktop.vue';

export default {
  name: 'DiamondPlan',
  components: {
    StripeCheckout,
    MonthlyMobile,
    MonthlyDesktop,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      selectedPlan: 'quarterly',
      sessionId: null,
      isLoading: false,
    };
  },
  computed: {
    getCheckout() {
      return this.$store.getters.getCheckout;
    },
    computedSelectedPlan() {
      return this.selectedPlan || 'quarterly';
    },
    computedPlan() {
      if (this.selectedPlan == 'quarterly') {
        return this.user.diamond_config.quarterly;
      } else if (this.selectedPlan == 'monthly') {
        return this.user.diamond_config.monthly;
      } else if (this.selectedPlan == 'semester') {
        return this.user.diamond_config.semester;
      } else {
        return this.user.diamond_config.yearly;
      }
    },
    computedHasTrial() {
      return this.computedPlan && this.computedPlan.trial_days > 0;
    },
    computedTrial() {
      return this.computedPlan.trial_days;
    },
    computedHasDiscount() {
      if (this.selectedPlan == 'quarterly') {
        return this.user.diamond_config.quarterly.coupon;
      } else {
        return this.user.diamond_config.monthly.coupon;
      }
    },
    computedDiscountText() {
      let plan;

      if (this.selectedPlan == 'quarterly') {
        plan = this.user.diamond_config.quarterly;
      } else {
        plan = this.user.diamond_config.monthly;
      }

      if (plan.coupon.amount_off) {
        return `${this.computedFormattedCurrency}${plan.coupon.amount_off}`
      } else {
        return `${plan.coupon.percent_off}%`
      }
    },
    computedFormattedCurrency() {
      if (this.user.currency == 'BRL') {
        return 'R$';
      } else if (this.user.currency == 'EUR') {
        return '€'
      } else if (this.user.currency == 'USD') {
        return 'US$'
      } else {
        return 'R$'
      }
    },
    computedHasAffiliate() {
      return this.user.affiliate_name;
    },
    computedIsLoading() {
      return this.isLoading || false;
    },
    computedShowQuarterlyFirst() {
      if (this.user.interest !== 'dropshipping' && this.user.sales_range.length == 1 && this.user.sales_range[0] == 0) {
        return false;
      } else {
        return true;
      }
    },
    computedShowToggle() {
      return !this.user.affiliate_name
    },
  },
  methods: {
    goToStripeCheckout(stripe_id) {
      let amount = parseInt(this.computedPlan.amount)
      let isTrial = this.computedHasTrial

      let successUrl = `${process.env.VUE_APP_API_URL}/users/me/subscriptions/new?session_id={CHECKOUT_SESSION_ID}&value=${amount}&trial=${isTrial}`
      let cancelUrl = `${process.env.VUE_APP_APP_URL}/plano-recomendado`

      const data = {
        mode: 'subscription',
        price_id: stripe_id,
        cancel_url: cancelUrl,
        success_url: `${process.env.VUE_APP_API_URL}/users/session_authorize/new?email=${this.user.email}&token=${this.user.token}&redirect=${successUrl}`,
      };

      if (this.user.utm_source == 'trends') {
        data.success_url = `${process.env.VUE_APP_TRENDS_FRONT_URL}/checkout/success?session_id={CHECKOUT_SESSION_ID}`
      }

      this.$mixpanel.track('register_front_clicked_subscribe', {
        email: this.user.email,
        plan_kind: 'diamond',
        occurrence: 'monthly',
        gateway: 'stripe',
      });
      this.isLoading = true;
      this.$store.dispatch('createCheckout', data);
    },
    goToGuruCheckout(checkout_url) {
      this.$mixpanel.track('register_front_clicked_subscribe', {
        email: this.user.email,
        plan_kind: 'diamond',
        occurrence: 'quarterly',
        gateway: 'guru',
      });
      window.location.href = `${checkout_url}?email=${this.user.email}&name=${this.user.name}`;
    },
    showModal() {
      if (this.computedHasAffiliate) {
        return;
      }
      if (window.innerWidth < 600) {
        this.$modal.show('modal-mobile');
      } else {
        this.$modal.show('modal-desktop');
      }
    },
    successDesktop() {
      this.$modal.hide('modal-desktop');
      return this.selectedPlan = 'quarterly';
    },
    successMobile() {
      this.$modal.hide('modal-mobile');
      return this.selectedPlan = 'quarterly';
    },
  },
  watch: {
    getCheckout(checkout) {
      if (checkout.session_id) {
        this.$segment.identify(this.user.id, {
          email: this.user.email,
          name: this.user.name,
          phone: this.user.phone,
          interest: this.user.interest,
          sales_range: this.user.sales_range
        });
        this.$segment.track('initiate_checkout', {
          revenue: this.selectedPlan == 'quarterly' ? this.computedPlan.amount * 3 : this.computedPlan.amount,
          products: [{
            id: this.computedPlan.stripe_id,
            currency: this.user.currency,
            quantity: 1,
            price: this.selectedPlan == 'quarterly' ? this.computedPlan.amount * 3 : this.computedPlan.amount
          }]
        });

        this.sessionId = checkout.session_id;
        this.$refs.checkoutRef.redirectToCheckout();
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.selectedPlan = this.user.affiliate_name || !this.computedShowQuarterlyFirst ? 'monthly' : 'quarterly';
  },
};
</script>

<style>
.monthly-text {
  background: linear-gradient(180deg, #F29932 0%, #E75341 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: bold !important;
}

.monthly-affiliate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #242424;
  padding: 6px 8px;
  border-radius: 4px;
}

.monthly-affiliate-background { 
  background-color: #3A3A3A;
  padding: 4px 8px;
  border-radius: 4px;
}

.monthly-affiliate-text {
  color: #D2D2D2;
  font-weight: bold;
  background-color: #3A3A3A;
  border-radius: 4px;
  padding: 0 !important;
}

@media (max-width: 990px) {
  .monthly-affiliate-container .monthly-affiliate-background .monthly-affiliate-text.table {
    padding: 0 !important;
    background-color: red !important;
    color: pink !important;
  }

  .monthly-affiliate-container{
    margin-top: 40px;
  }

  .pricing-currency.v2 {
      font-size: 14px;
      line-height: 20px;
      position: absolute;
      right: 176px !important;
  }
}
</style>
